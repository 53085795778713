import React from 'react';
import { Link } from 'gatsby';
import { Logo } from './brand';

const Navbar = class extends React.Component {
	state = {
		active: false,
		navBarActiveClass: ''
	};

	toggleHamburger = () => {
		// toggle the active boolean in the state
		this.setState(
			{
				active: !this.state.active
			},
			// after state has been updated,
			() => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
							navBarActiveClass: 'is-active'
						})
					: this.setState({
							navBarActiveClass: ''
						});
			}
		);
	};

	render() {
		return (
			<nav className="navbar is-light is-fixed-top" role="navigation" aria-label="main-navigation">
				<div className="container">
					<div className="navbar-brand">
						<Link to="/" className="navbar-item" title="Logo">
							<div
								style={{
									// height: '250px'
									width: '120px'
								}}
							>
								<Logo />
							</div>
						</Link>

						<div
							className={`navbar-burger burger ${this.state.navBarActiveClass}`}
							data-target="navMenu"
							onClick={() => this.toggleHamburger()}
						>
							<span />
							<span />
							<span />
						</div>
					</div>
					<div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
						<div className="navbar-start has-text-centered has-text-weight-bold is-size-6">
							<Link className="navbar-item" to="/mobile-wallet">
								Personal
							</Link>
							<Link className="navbar-item" to="/business">
								Business
							</Link>
						</div>
						<div className="navbar-end has-text-centered has-text-weight-bold is-size-5">
							<Link className="navbar-item" to="/how-it-works">
								Commerce Platform
							</Link>
							<Link className="navbar-item" to="/benefits">
								Digital Account
							</Link>

							<Link className="navbar-item" to="/faq">
								Payments
							</Link>
							<Link className="navbar-item" to="/get-started">
								Marketing
							</Link>
						</div>
						<div className="navbar-end">
							<div className="navbar-item">
								<div className="buttons is-centered">
									<a href="https://app.rubybusiness.com/" className="button is-primary">
										<strong>GET STARTED</strong>
									</a>
									<a href="https://app.rubybusiness.com/" className="button is-light">
										Sign in
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		);
	}
};

export default Navbar;
