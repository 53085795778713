import React from 'react';
// import { Link } from 'gatsby';
import { Columns, Column, Container, Footer, Level, Left, Item, Right, Menu, MenuItem } from './bulma';

import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
import twitter from '../img/social/twitter.svg';
import linkedin from '../img/social/linkedin.svg';

import appStore from '../img/AppStore.svg';
import googlePlay from '../img/GooglePlay.svg';
import finhub from '../img/finhub-logo.svg';

export default () => {
	return (
		<Footer>
			<Container>
				<Columns>
					<Column>
						<Menu label="Why Ruby">
							<MenuItem to="/mobile-wallet">Sell everywhere</MenuItem>
							<MenuItem to="/digital-account">Manage Finances</MenuItem>
							<MenuItem to="/payment-processing">Process Payments</MenuItem>
							<MenuItem to="/credit-card">Talk to Customers</MenuItem>
							<MenuItem to="/credit-card">Increase Profitability</MenuItem>
							<MenuItem to="/pricing">Pricing</MenuItem>
						</Menu>
					</Column>
					<Column>
						<Menu label="Platform">
							<MenuItem to="/mobile-wallet">Omni-channel Commerce</MenuItem>
							<MenuItem to="/digital-account">Digital Account</MenuItem>
							<MenuItem to="/credit-card">Payments Processing</MenuItem>
							<MenuItem to="/get-started">Marketing Communications</MenuItem>
							<MenuItem to="/get-started">Customer Service</MenuItem>
						</Menu>
					</Column>
					<Column>
						<Menu label="Solutions">
							<MenuItem to="/small-business">Contactless Payments</MenuItem>
							<MenuItem to="/small-business">Fast Checkout</MenuItem>
							<MenuItem to="/small-business">Exclusive Offers</MenuItem>
							<MenuItem to="/medium-business">Cashback</MenuItem>
							<MenuItem to="/brands">Personal Service</MenuItem>
							<MenuItem to="/marketing-agencies">Store Credit</MenuItem>
						</Menu>
					</Column>

					<Column>
						<Menu label="About">
							<MenuItem to="/blog">Blog</MenuItem>
							<MenuItem to="/faq">FAQ</MenuItem>
							<MenuItem to="/about">About</MenuItem>
							<MenuItem to="/mission">Mission</MenuItem>
							<MenuItem to="/news">News</MenuItem>
							<MenuItem to="/contact">Contact</MenuItem>
						</Menu>
					</Column>
				</Columns>
				<br />

				{/* <Level>
					<Left>
						<p>
							<a title="facebook" href="https://www.facebook.com/cashmarkapp">
								<img
									src={facebook}
									alt="Facebook"
									style={{ width: '2em', height: '2em', marginRight: 15 }}
								/>
							</a>
							<a title="twitter" href="https://twitter.com/cashmark_app">
								<img
									src={twitter}
									alt="Twitter"
									style={{ width: '2em', height: '2em', marginRight: 15 }}
								/>
							</a>
							<a title="linkedin" href="https://www.linkedin.com/company/cashmark/">
								<img
									src={linkedin}
									alt="Twitter"
									style={{ width: '2em', height: '2em', marginRight: 15 }}
								/>
							</a>
							<a title="instagram" href="https://www.instagram.com/cashmark_app/">
								<img src={instagram} alt="Instagram" style={{ width: '2em', height: '2em' }} />
							</a>
						</p>
					</Left>
					<Item>
						<div id="navMenu" className={`navbar-menu`}>
							<div className="navbar-end has-text-centered has-text-weight-semibold">
								<Link className="navbar-item" to="/terms">
									Terms of service
								</Link>
								<Link className="navbar-item" to="/privacy">
									Privacy
								</Link>

								<Link className="navbar-item" to="/contact">
									Contact
								</Link>
								<Link className="navbar-item" to="/faq">
									FAQ
								</Link>
							</div>
						</div>
					</Item>
					<Link to="/privacy">Privacy</Link>
							<Link to="/contact">Contact</Link>
						</div>
					</Item>
					<Item />
					<Item />

					<Right>
						<p>
							<a title="App Store" href="#">
								<img src={appStore} alt="App Store" />
							</a>{' '}
							<a title="Google Play" href="#">
								<img className="fas fa-lg" src={googlePlay} alt="Google Play" />
							</a>
						</p>
					</Right>
				</Level> */}

				<Level>
					<Left>
						<p className="has-text-left is-size-7">
							<strong>© 2020 Ruby</strong>. A{' '}
							<a title="FinHub Global Ventures" href="https://finhub.vc/" target="_blanc">
								FinHub Global Ventures
							</a>{' '}
							company. All rights reserved.
							<br />All product names, trademarks and registered trademarks are property of their
							respective owners.
							<br />All company, product and service names used in this website are for identification
							purposes only.
							<br />Use of these names, trademarks and brands does not imply endorsement.
							<br />Website by{' '}
							<a title="Superfast" href="https://superfast.page/" target="_blanc">
								Superfast
							</a>.
						</p>
					</Left>
					<Right>
						<div
							style={{
								width: '120px',
								display: 'inline-block'
							}}
						>
							<a title="FinHub Global Ventures" href="https://finhub.vc/" target="_blanc">
								<img src={finhub} alt="FinHub Global Ventures logo" />
							</a>
						</div>
					</Right>
				</Level>
				<br />
			</Container>
			<br />
			<br />
		</Footer>
	);
};
