import React from 'react';

const CTA = ({ partner }) => (
	<section className="hero is-primary is-bold">
		<div className="hero-body">
			<div className="container">
				<div className="content has-text-centered">
					<h1 className="title">Create your free account now!</h1>
					<h2 className="subtitle">Next day funding. No contract. No hidden fees.</h2>
					<br />
					<a href="https://app.rubybusiness.com/" className="button is-light is-large">
						<strong>GET STARTED</strong>
					</a>
				</div>
			</div>
		</div>
	</section>
);

export default CTA;
