import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import {
	faSquare,
	faMobile,
	faAtom,
	faCode,
	faShareSquare,
	faSearch,
	faTachometerAlt,
	faCodeBranch,
	faShieldAlt,
	faClock,
	faTag,
	faServer,
	faCalendarAlt,
	faShoppingCart,
	faArrowAltCircleRight,
	faFilePdf,
	faStore,
	faPortrait
} from '@fortawesome/free-solid-svg-icons';

export default () => {
	library.add(
		faSquare,
		faShieldAlt,
		faClock,
		faTag,
		faServer,
		faMobile,
		faAtom,
		faCode,
		faShareSquare,
		faSearch,
		faTachometerAlt,
		faCodeBranch,
		faCalendarAlt,
		faShoppingCart,
		faArrowAltCircleRight,
		faFilePdf,
		faStore,
		faPortrait,
		faShareSquare,
		faSearch
	);
};
