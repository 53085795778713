import React from 'react';
import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Icons from '../config/icons';
import './all.sass';

// import PageTransition from './display/PageTransitions';

import useSiteMetadata from './SiteMetadata';

Icons();

const TemplateWrapper = ({ children, location }) => {
	const { title, description } = useSiteMetadata();
	return (
		<div>
			<Helmet>
				<html lang="en" />
				<meta charSet="utf-8" />
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />

				<title>{title}</title>
				<meta name="description" content={description} />

				<link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
				<link rel="icon" type="image/png" href="/img/favicon-32x32.png" sizes="32x32" />
				<link rel="icon" type="image/png" href="/img/favicon-16x16.png" sizes="16x16" />
				<link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
				<meta name="msapplication-TileColor" content="#fff" />
				<meta name="theme-color" content="#fff" />

				<meta property="og:type" content="business.business" />
				<meta property="og:title" content={title} />
				<meta property="og:url" content="/" />
				<meta property="og:image" content="/img/og-image.jpg" />
			</Helmet>
			<Navbar />

			{/* <PageTransition location={location}> */}
			{children}
			{/* </PageTransition> */}

			<Footer />
		</div>
	);
};

export default TemplateWrapper;
